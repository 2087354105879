<template>
  <section>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-table
                striped
                hover
                responsive
                :items="_products"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
            >

              <template #cell(supplier)="data">
                <b-badge variant="success">{{ data.item.supplier.name }}</b-badge>
                <br>
                <b-badge>{{ data.item.stock_return == 27 ? 'İade' : 'Stok' }}</b-badge>
              </template>

              <template #cell(image)="data">
                <ProductImages
                    v-if="data.item.product"
                    prefix="https://api.kitapfirsatlari.com/"
                    :images="data.item.product.images"/>
              </template>

              <template #cell(product)="data">
                <b-badge variant="dark" v-if="data.item.product">{{ data.item.product.barcode }}</b-badge>
                <br>
                <b-badge v-if="data.item.product">{{ data.item.product.id }}</b-badge>
                <br>
                <b-badge v-if="data.item.product">{{ data.item.product.name }}</b-badge>
              </template>

              <template #cell(detail2)="data">
                Ekleyen : {{ userName(data.item.add_user_id) }}
                Onaylayan : {{ userName(data.item.approver_user_id) }}
              </template>

              <template #cell(detail)="data">
                <b-row>
                  <b-col md="1" class="p-0 m-0"> Adet</b-col>
                  <b-col md="1" class="p-0 m-0"> Piyasa</b-col>
                  <b-col md="1" class="p-0 m-0"> İskonto</b-col>
                  <b-col md="1" class="p-0 m-0"> Alış</b-col>
                </b-row>
                <b-row>
                  <b-col md="1" class="p-0 m-0">
                    <b-form-input
                        size="sm"
                        v-model="data.item.quantity"
                        disabled
                    />
                  </b-col>
                  <b-col md="1" class="p-0 m-0">
                    <b-form-input
                        size="sm"
                        v-model="data.item.market_price"
                        @keyup.enter="calculated('market_price')"
                        disabled
                    />
                  </b-col>
                  <b-col md="1" class="p-0 m-0">
                    <b-form-input
                        size="sm"
                        v-model="data.item.discount"
                        @keyup.enter="calculated('discount')"
                        disabled
                    />
                  </b-col>
                  <b-col md="1" class="p-0 m-0">
                    <b-form-input
                        size="sm"
                        v-model="data.item.price"
                        @keyup.enter="calculated('price')"
                        disabled
                    />
                  </b-col>
                </b-row>
              </template>

              <template #cell(subItems)="data">
                <pre>
                  {{ data.item.sub_items }}
                </pre>
              </template>

              <template #cell(yonetim)="data">
                <b-button-group size="sm">
                  <b-button variant="success" @click="increase">
                    <feather-icon icon="PlusCircleIcon"></feather-icon>
                  </b-button>
                  <b-button variant="warning" @click="decrease(data.item)">
                    <feather-icon icon="MinusCircleIcon"></feather-icon>
                  </b-button>
                </b-button-group>
              </template>

            </b-table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {BTable, BAvatar, BBadge, BRow, BCol, BPagination,} from 'bootstrap-vue'
import ProductImages from "@/components/ProductImages";
import SupplierProduct from "@/views/product/supplierProduct";
import {mapGetters} from "vuex";


export default {
  name: "StockCardList",
  components: {BTable, BAvatar, BBadge, BRow, BCol, BPagination, ProductImages, SupplierProduct},
  data: () => ({
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    fields: [
      {key: 'id', label: 'Sıra no'},
      {key: 'supplier', label: 'Tedarikçi'},
      {key: 'shelve_name', label: 'Raf Adı', sortable: true},
      {key: 'image', label: 'Resim'},
      {key: 'product', label: 'Ürün Bilgisi'},
      {key: 'detail', label: 'Detay'},
      {key: 'detail2', label: 'Detay2'},
      {key: 'subItems', label: 'Alt Stoklar'},
      {key: 'yonetim', label: 'Yönetim'},

    ]
  }),
  computed: {
    ...mapGetters("stock", ["_products"]),
    ...mapGetters('users', ['_users']),
  },
  methods: {
    userName(id) {
      let user = this._users.find(user => user.id == id);
      if (!user) return '';
      return user.short_name;
    },
    increase(stockCard) {

    },
    decrease(stockCard) {
      this.$swal({
        title: 'Çıkartılacak Adet Belirt',
        html: '<input id="quantity" type="number" class="swal2-input" placeholder="Adet">' +
            '<input id="message" type="text" class="swal2-input" placeholder="Sebep belirtiniz">',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: 'Azalt',
        buttonsStyling: false,
        preConfirm: () => {
          let quantity = document.getElementById('quantity').value
          let message = document.getElementById('message').value
          if (!quantity || !message) return null;
          let data = {
            discount: stockCard.discount,
            in_out: '-',
            market_price: stockCard.market_price,
            parent_id: stockCard.id,
            price: stockCard.price,
            product_id: stockCard.product_id,
            quantity,
            shelve_id: stockCard.shelve_id,
            shelve_name: stockCard.shelve_name,
            status: 1,
            stock_return: stockCard.stock_return,
            supplier_id: stockCard.supplier_id,
            vat_free_price: stockCard.vat_free_price,
            message
          }
          return this.$store.dispatch('stock/addnew', data)
              .then(res => {
                return res;
              })
              .catch(error => {
                this.$swal.showValidationMessage(`Request failed:  ${error}`)
              })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
