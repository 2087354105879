<template>
  <section>
    <StockCardSearch></StockCardSearch>
    <StockCardList></StockCardList>
  </section>
</template>

<script>
import StockCardSearch from "@/views/stock/card/StockCardSearch";
import StockCardList from "@/views/stock/card/StockCardList";

export default {
  name: "StokCardHome",
  components: {StockCardSearch, StockCardList},
}
</script>

<style scoped>

</style>
