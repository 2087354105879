<template>
  <b-row>
    <b-col md="3" class="p-0 m-0">
      <b-form-input
          size="sm"
          v-model="supplierProduct.supplier.name"
          disabled
          :class="supplierId==supplierProduct.supplier.id?'bg-success':''"
      />
    </b-col>
    <b-col md="3" class="p-0 m-0">
      <b-form-input
          size="sm"
          v-model="supplierProduct.barcode"
          disabled
          :class="supplierId==supplierProduct.supplier.id?'bg-success':''"
      />
    </b-col>
    <b-col md="1" class="p-0 m-0">
      <b-form-input
          size="sm"
          v-model="supplierProduct.stock"
          disabled
          :class="supplierId==supplierProduct.supplier.id?'bg-success':''"
      />
    </b-col>
    <b-col md="1" class="p-0 m-0">
      <b-form-input
          size="sm"
          v-model="supplierProduct.market_price"
          disabled
          :class="supplierId==supplierProduct.supplier.id?'bg-success':''"
      />
    </b-col>
    <b-col md="1" class="p-0 m-0">
      <b-form-input
          size="sm"
          v-model="supplierProduct.discount"
          disabled
          :class="supplierId==supplierProduct.supplier.id?'bg-success':''"
      />
    </b-col>
    <b-col md="1" class="p-0 m-0">
      <b-form-input
          size="sm"
          v-model="supplierProduct.price"
          disabled
          :class="supplierId==supplierProduct.supplier.id?'bg-success':''"
      />
    </b-col>
    <b-col md="2" class="p-0 m-0">
      <b-button-group>
        <b-button
            variant="outline-primary"
            size="sm"
            @click="matchUp(supplierProduct)"
        >
          Eşitle
        </b-button>
      </b-button-group>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: "supplierProduct",
  components: {},
  props: {
    supplierProduct: {
      type: Object,
      required: true
    },
    stock: {
      type: Object,
      required: true
    },
    supplierId: {
      type: Number
    }
  },
  methods: {
    matchUp(supplierProduct) {
      this.stock.market_price = supplierProduct.market_price;
      this.stock.price = supplierProduct.price;
      this.stock.discount = supplierProduct.discount

    },
  }
}
</script>

<style scoped>

</style>
