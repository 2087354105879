<template>
  <section>
    <div class="row">
      <div class="col-md-12">
        <b-overlay
            :show="show"
            rounded="sm"
        >
          <div class="card">
            <div class="card-body">
              <b-form @submit.prevent>
                <b-row>
                  <b-col md="10">
                    <b-row>

                      <!-- Eşleşme Statüsü -->
                      <b-col md="2">
                        <b-form-group label="Eşleşme Statüsü">
                          <v-select
                              v-model="_search.status"
                              label="status"
                              multiple
                              :options="_matchStatus"
                              :reduce="statu => statu.id"
                              class="select-size-sm"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- //Eşleşme Statüsü -->

                      <b-col md="2">
                        <b-form-group label="Stok İade">
                          <v-select
                              v-model="_search.stock_return"
                              label="name"
                              multiple
                              :options="stock_return"
                              :reduce="item => item.id"
                              class="select-size-sm"
                          />
                        </b-form-group>
                      </b-col>


                      <b-col md="2">
                        <b-form-group label="Raf Adı">
                          <b-form-input type="text" size="sm" v-model="_search.shelve_name"/>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Ürün No">
                          <b-form-input type="text" size="sm" v-model="_search.product.id"/>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Barkod">
                          <b-form-input type="text" size="sm" v-model="_search.product.barcode"/>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Ürün Adı">
                          <b-form-input type="text" size="sm" v-model="_search.product.name"/>
                        </b-form-group>
                      </b-col>


                      <b-col md="2">
                        <b-form-group label="Yayımevleri">
                          <v-select
                              v-model="_search.publishers"
                              multiple
                              label="publisher_name"
                              :options="publishersed"
                              :reduce="publisher => publisher.id"
                              class="select-size-sm"
                              :filterable="false"
                              @search="onSearch"
                          >
                            <li slot="list-footer" class="pagination">
                              <button :disabled="!hasPrevPage" @click="offset -= limit">Prev</button>
                              <button :disabled="!hasNextPage" @click="offset += limit">Next</button>
                            </li>
                          </v-select>
                        </b-form-group>
                      </b-col>

                      <!-- LİMİT -->
                      <b-col md="2">
                        <b-form-group label="Limit">
                          <v-select
                              v-model="_search.limit"
                              :options="[1,5,10,20,50,100,250,500]"
                              class="select-size-sm"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- //LİMİT -->


                      <!-- SAYFA -->
                      <b-col md="2">
                        <b-form-group label="Sayfa">
                          <b-form-input type="number" size="sm" v-model="_search.page"/>
                        </b-form-group>
                      </b-col>
                      <!-- //SAYFA -->
                    </b-row>
                    <b-row v-if="_pagination">
                      Sayfa: {{ _pagination.current_page }} / {{ _pagination.last_page }}
                      Ürün: {{ _pagination.from }} ile {{ _pagination.to }} arası {{ _pagination.per_page }} / {{
                        _pagination.total
                      }}
                    </b-row>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Yönetim">
                      <b-button-group size="sm">
                        <b-button variant="primary" @click="getAll">Getir</b-button>
                        <b-button variant="danger" @click="clearSearch">Temizle</b-button>
                        <b-button variant="warning" @click="getAllPublishers">Yayımevleri</b-button>
                      </b-button-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import vSelect from "vue-select";

export default {
  name: "StockCardSearch",
  components: {vSelect},
  data: () => ({
    show: false,
    stock_return: [{id: 9, name: 'Stok'}, {id: 27, name: 'İade'}]
  }),
  computed: {
    ...mapGetters('matchStatus', ["_matchStatus"]),
    ...mapGetters('stock', ["_search", "_pagination"]),
    ...mapGetters('productPublisher', ["_publishers"]),
    filtered() {
      return this._publishers.filter((publisher) =>
          publisher.publisher_name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
      )
    },
    publishersed() {
      return this.filtered.slice(this.offset, this.limit + this.offset)
    },
    hasNextPage() {
      const nextOffset = this.offset + this.limit
      return Boolean(
          this.filtered.slice(nextOffset, this.limit + nextOffset).length
      )
    },
    hasPrevPage() {
      const prevOffset = this.offset - this.limit
      return Boolean(
          this.filtered.slice(prevOffset, this.limit + prevOffset).length
      )
    },
  },
  methods: {
    getAll() {
      this.show = true
      this.$store.dispatch('stock/getAll').then(
          res => {
            this.show = false;
          }
      )
    },
    clearSearch() {

    },
    getAllPublishers() {
      this.$store.dispatch('productPublisher/getAll');
    },
    onSearch(query) {
      this.search = query
      this.offset = 0
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
